import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import MarkdownEditor from '@/components/FormInputs/MarkdownEditor/MarkdownEditor.vue';
let TextareaInput = class TextareaInput extends Vue {
    constructor() {
        super(...arguments);
        this.options = {
            language: 'en-US',
            minHeight: '100px',
            useCommandShortcut: true,
            useDefaultHTMLSanitizer: true,
            usageStatistics: false,
            hideModeSwitch: true,
            toolbarItems: this.showLink ? ['bold', 'italic', 'link'] : ['bold', 'italic'],
        };
    }
    markdownChange(value) {
        this.$emit('input', value);
    }
    mounted() { }
};
__decorate([
    PropSync('value', { type: String })
], TextareaInput.prototype, "textareaValue", void 0);
__decorate([
    Prop()
], TextareaInput.prototype, "id", void 0);
__decorate([
    Prop()
], TextareaInput.prototype, "label", void 0);
__decorate([
    Prop()
], TextareaInput.prototype, "placeholder", void 0);
__decorate([
    Prop()
], TextareaInput.prototype, "error", void 0);
__decorate([
    Prop({ default: false })
], TextareaInput.prototype, "optionalText", void 0);
__decorate([
    Prop({ default: false })
], TextareaInput.prototype, "showLink", void 0);
TextareaInput = __decorate([
    Component({ components: { MarkdownEditor } })
], TextareaInput);
export default TextareaInput;
