import { __decorate } from "tslib";
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import LabelPillButton from '@/components/Label/LabelPillButton/LabelPillButton.vue';
let LabelSelect = class LabelSelect extends Vue {
    constructor() {
        super(...arguments);
        this.selectedLabels = [];
    }
    onSelectedLabelsUpdate() {
        let newArray = [];
        for (let index = 0; index < this.selectedLabels.length; index++) {
            const element = this.selectedLabels[index];
            newArray.push(element.id);
        }
        this.returnValue = newArray;
    }
    hasBeenSelected(labelId) {
        if (this.selectedLabels) {
            for (let i = 0; i < this.selectedLabels.length; i++) {
                const element = this.selectedLabels[i];
                if (element.id === labelId) {
                    return true;
                }
            }
            return false;
        }
    }
    async mounted() {
        await this.$store.dispatch('label/getAllLabels');
        // If this value has an array of existing labels we should add them to the selectedLabels array
        // and then update the returnValue to the id's of those labels
        // we use next tick to make sure the values are in
        this.$nextTick(() => {
            if (this.returnValue.length > 0) {
                const incomingLabels = this.returnValue.slice(0); // creates a clone of the array
                this.returnValue = []; // reset the outgoing array before we populate it
                this.$nextTick(() => {
                    incomingLabels.forEach((label) => {
                        if (typeof label === 'object') {
                            this.addLabel(label);
                        }
                    });
                });
            }
        });
    }
    async destroyed() {
        this.$nextTick(() => {
            this.returnValue = [];
        });
    }
    addLabel(label) {
        if (!this.returnValue.includes(label.id)) {
            this.returnValue.push(label.id);
            this.selectedLabels.push(label);
        }
    }
    removeLabel(label) {
        // remove item from returnValue
        const returnValueId = this.returnValue.indexOf(label.id);
        this.returnValue.splice(returnValueId, 1);
        // remove item from selectedLabels array
        const selectedLabelId = this.selectedLabels.findIndex((selectedLabel) => selectedLabel.id === label.id);
        this.selectedLabels.splice(selectedLabelId, 1);
    }
};
__decorate([
    PropSync('value', { type: Array })
], LabelSelect.prototype, "returnValue", void 0);
__decorate([
    Prop()
], LabelSelect.prototype, "id", void 0);
__decorate([
    Prop()
], LabelSelect.prototype, "label", void 0);
__decorate([
    Prop()
], LabelSelect.prototype, "error", void 0);
__decorate([
    Prop({ default: false })
], LabelSelect.prototype, "formOpen", void 0);
__decorate([
    Getter('getLabels', { namespace: 'label' })
], LabelSelect.prototype, "labels", void 0);
__decorate([
    Watch('selectedLabels')
], LabelSelect.prototype, "onSelectedLabelsUpdate", null);
LabelSelect = __decorate([
    Component({ components: { LabelPillButton } })
], LabelSelect);
export default LabelSelect;
