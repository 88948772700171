import { __decorate } from "tslib";
import { Component, Prop, Vue, PropSync } from 'vue-property-decorator';
let CheckboxInput = class CheckboxInput extends Vue {
    mounted() { }
};
__decorate([
    PropSync('value', { type: Boolean })
], CheckboxInput.prototype, "selectedValue", void 0);
__decorate([
    Prop()
], CheckboxInput.prototype, "id", void 0);
__decorate([
    Prop()
], CheckboxInput.prototype, "label", void 0);
__decorate([
    Prop()
], CheckboxInput.prototype, "description", void 0);
__decorate([
    Prop()
], CheckboxInput.prototype, "placeholder", void 0);
__decorate([
    Prop()
], CheckboxInput.prototype, "error", void 0);
CheckboxInput = __decorate([
    Component
], CheckboxInput);
export default CheckboxInput;
