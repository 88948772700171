import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import Btn from '@/components/Button/Btn.vue';
import { Getter } from 'vuex-class';
let UpdateAvatar = class UpdateAvatar extends Vue {
    constructor() {
        super(...arguments);
        this.hasProfile = false;
    }
    mounted() {
        this.hasProfile = this.profile ? true : false;
    }
    selectFile() {
        // @ts-ignore
        this.selectedFiles = this.$refs.file.files;
    }
    // Submit form here
    submit() {
        try {
            this.$store.dispatch('user/updateUserAvatar', this.selectedFiles.item(0));
        }
        catch (e) {
            console.log('Avatar', e);
        }
    }
};
__decorate([
    Getter('getProfile', { namespace: 'user' })
], UpdateAvatar.prototype, "profile", void 0);
__decorate([
    Prop({ default: 'medium' })
], UpdateAvatar.prototype, "size", void 0);
UpdateAvatar = __decorate([
    Component({ components: { Btn } })
], UpdateAvatar);
export default UpdateAvatar;
