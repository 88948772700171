import { __decorate } from "tslib";
import { Component, Prop, PropSync, Watch, Vue } from 'vue-property-decorator';
import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from "@toast-ui/vue-editor";
let MarkdownEditor = class MarkdownEditor extends Vue {
    constructor() {
        super(...arguments);
        this.defaultOptions = {
            language: 'en-US',
            minHeight: '100px',
            useCommandShortcut: true,
            useDefaultHTMLSanitizer: true,
            usageStatistics: false,
            hideModeSwitch: true,
            toolbarItems: [
                'heading',
                'bold',
                'italic',
                'strike',
                'divider',
                'hr',
                'quote',
                'divider',
                'ul',
                'ol',
                'task',
                'indent',
                'outdent',
                'divider',
                'table',
                'image',
                'link',
                'divider',
                'code',
                'codeblock',
            ],
        };
    }
    onCurrentTextChange(n, o) {
        // if we invoke this after each change the users cursor goes to the end of the input.
        // if new value is empty and not the same as the old value update
        if (!n && o && n !== o) {
            // @ts-ignore
            this.$refs.toastuiEditor.invoke('setMarkdown', n);
            // if the new value is blank and the same as the old value
        }
        else if (!n && o && n === o) {
            // @ts-ignore
            this.$refs.toastuiEditor.invoke('setMarkdown', o);
        }
        // @ts-ignore
        this.markdownText = this.$refs.toastuiEditor.invoke('getMarkdown');
        // @ts-ignore
        this.$emit('updatedMarkdown', this.$refs.toastuiEditor.invoke('getMarkdown'));
    }
    get editorOptions() {
        return this.options ? this.options : this.defaultOptions;
    }
    onEditorChange() {
        // @ts-ignore
        this.markdownText = this.$refs.toastuiEditor.invoke('getMarkdown');
        // @ts-ignore
        this.$emit('updatedMarkdown', this.$refs.toastuiEditor.invoke('getMarkdown'));
    }
};
__decorate([
    PropSync('markdowncontent', { type: String })
], MarkdownEditor.prototype, "markdownText", void 0);
__decorate([
    Prop()
], MarkdownEditor.prototype, "options", void 0);
__decorate([
    Prop()
], MarkdownEditor.prototype, "currentText", void 0);
__decorate([
    Watch('currentText')
], MarkdownEditor.prototype, "onCurrentTextChange", null);
MarkdownEditor = __decorate([
    Component({ components: { editor: Editor } })
], MarkdownEditor);
export default MarkdownEditor;
