import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let LabelPillButton = class LabelPillButton extends Vue {
};
__decorate([
    Prop()
], LabelPillButton.prototype, "label", void 0);
__decorate([
    Prop({ default: true })
], LabelPillButton.prototype, "primary", void 0);
__decorate([
    Prop({ default: 'small' })
], LabelPillButton.prototype, "size", void 0);
LabelPillButton = __decorate([
    Component
], LabelPillButton);
export default LabelPillButton;
