var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"flex-1 flex flex-wrap justify-between",attrs:{"data-component":"avatar"}},[(_vm.profile)?_c('div',{staticClass:"flex-1 flex items-center"},[_c('div',{staticClass:"mr-2"},[(_vm.profile.avatar && _vm.profile.avatar.public_url)?_c('img',{staticClass:"rounded-full",class:[
                    { 'h-8 w-8 mr-1': _vm.size === 'small' }, 
                    { 'h-10 w-10 mr-2': _vm.size === 'medium' },
                    { 'h-12 w-12 mr-2': _vm.size === 'large' } ],attrs:{"src":_vm.profile.avatar.public_url,"alt":""}}):_vm._e()]),_c('div',{staticClass:"truncate",class:[
                { 'w-40': _vm.size === 'small' || _vm.size === 'medium' }, 
                { 'w-56': _vm.size === 'large' } ]},[_c('label',{class:[
                { 'text-xxs': _vm.size === 'small' }, 
                { 'text-xs': _vm.size === 'large' || _vm.size === 'medium' } ]},[_c('input',{ref:"file",attrs:{"type":"file","accept":"image/jpeg"},on:{"change":_vm.selectFile}})])])]):_vm._e(),_c('div',{staticClass:"flex justify-end py-3"},[_c('btn',{staticClass:"w-16",attrs:{"size":"small","label":_vm.$i18n.t('form.button.save')},on:{"click":function($event){return _vm.submit()}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }